import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import {
  LandlordsHero,
  SinglePropertyAdvice,
  LandlordLandingInfo,
  AboutOptions,
  HomeInstantValuation,
} from 'components/sections';
import { TripleImageSection } from 'components/blocks';
import landlordsPageMotion from 'motion/landlords.js';

import { ViewportEnterAnimation } from 'components/ui';
import { functionSinglePropertyAdvice } from 'motion/landlords.js';
import { functionAboutAccordion } from 'motion/about.js';
import { tripleImageSectionScroll } from 'motion/buy.js';
import {functionHomeInstantValuation } from 'motion/index.js';


const ctaClick = () => {
  if (typeof window !== 'undefined') {
    window.openInstantValuation && window.openInstantValuation();
  }
}


const LandlordsPage = ({
  data: {
    pages: { landlords },
    imageBack,
    imageMiddle,
    imageFront,
  }

}) =>
 {

    const  img1 = {src:landlords.highlight.img1, height:314, width: 456};
    const  img2 = {src:landlords.highlight.img2, height:386, width: 346};
    const  img3 = {src:landlords.highlight.img3, height:649, width: 416};

  return (
  <Layout onVisible={landlordsPageMotion}>
    <SEO title={landlords.seo.title} description={landlords.seo.meta} keywords={landlords.seo.keywords} />


    <LandlordsHero {...landlords.hero} />
    <LandlordLandingInfo
        content={landlords.landingInfo}
      />

    <ViewportEnterAnimation offset={100} animation={tripleImageSectionScroll}>
      <TripleImageSection
        content={landlords.highlight}
        buttonAction={() => console.log('lets discuss')}
        imageBack={img1}
        imageMiddle={img2}
        imageFront={img3}
        imageBackalt={landlords.highlight.img1alt}
        imageMiddlealt={landlords.highlight.img2alt}                     
        imageFrontalt={landlords.highlight.img3alt}
      />
    </ViewportEnterAnimation>

    <ViewportEnterAnimation offset={100} animation={functionAboutAccordion}>
      <AboutOptions name="landlords-page-faq" data={landlords.faq} />
    </ViewportEnterAnimation>

    <ViewportEnterAnimation offset={100} animation={functionHomeInstantValuation}>
      {/* <SinglePropertyAdvice onClick={ctaClick} content={landlords.callToAction} /> */}
      <HomeInstantValuation content={landlords.callToAction} />
    </ViewportEnterAnimation>
  </Layout>
)

}

export const query = graphql`
  query LandlordsPage {
    pages {
      landlords {
        pageTitle
        hero {
          title
          text
          img
        }
        landingInfo {
          title
          paragraph1
          paragraph2
          paragraph3
          callToAction
          img
        }
        highlight {
          title
          paragraph1
          paragraph2
          callToAction
          img1
          img2
          img3
          img1alt
          img2alt
          img3alt
        }
        callToAction {
          title
          subtitle
          btn
          img
        }
        faq {
          title
          questions {
            question
            answer
          }
        }
        seo{
          title
          keywords
          meta
        }
      }
    } 
  }
`;

export default LandlordsPage;
